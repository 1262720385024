import React from "react";
import { graphql } from 'gatsby'
//STYLES
import { CardWrapper, Section, ContainerMob, Logo } from '../utils/utils';
import { H1, LabelBW, Text } from "../utils/typo";
//COMPONENTS
import SEO from '../components/seo/SEO';
import Layout from '../layout/layoutBW';
import CardSimple from '../components/portfolioCard/CardSimple'

const Page3 = ({
    data: {
        allMdx: {
            nodes
        },
    },
}) => {

    return <div>
        <SEO
            title="Home"
            slug='/page3' />
        <Layout>
            <Logo />
            <Section
                data-sal="fade"
                data-sal-delay='100'
                data-sal-duration='600'>
                <ContainerMob>
                    <LabelBW mb='1rem'>Portfolio</LabelBW>
                    <H1 mb='1rem'>Realizacje</H1>
                    <Text>
                        Zapraszamy do zapoznania się z naszym portfolio, w którym znajdują sie zarówno realizacje stron i aplikacji jak również graficzne projekty, broszur, ulotek wizytówek i logo.
                </Text>
                </ContainerMob>
            </Section>

            <CardWrapper
                colGap='2rem'
                rowGap='2rem' mb='0'
                xl='3' l='3' m='3'
            >
                {nodes.map((node) => {
                    return (
                        <CardSimple
                            key={nodes.indexOf(node)}
                            name={node.frontmatter.title}
                            shortList={node.frontmatter.shortList}
                            link={node.frontmatter.slug}
                            image={node.frontmatter.mainImage.file.childImageSharp.gatsbyImageData}
                            alt={node.frontmatter.mainImage.alt}
                        />
                    )
                })}
            </CardWrapper>

        </Layout>
    </div >
}
export default Page3

export const query = graphql`
query {
    allMdx (
        filter: {frontmatter: {idPage: {eq: "portfolio"}}}
        sort: {order: DESC, fields: frontmatter___id}
        ){
        nodes {
            body
            frontmatter {
                title
                intro
                slug
                shortList
                mainImage{
                    alt
                    file {
                        childImageSharp {
                            gatsbyImageData(
                              layout: FULL_WIDTH
                              aspectRatio: 1
                              placeholder: BLURRED
                              )
                          }
                    }
                }
                
            }
        }
    }
}
`